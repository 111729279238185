import type { CustomerFilter } from '@futureecom/futureecom-js/dist/services/customer-service'
import type { FilterDefinition } from '@/types/FilterDefinitions'

export const customerFilters: FilterDefinition<CustomerFilter>[] = [
  { type: 'text', code: 'firstname', label: 'First name' },
  { type: 'text', code: 'lastname', label: 'Last name' },
  { type: 'text', code: 'email', label: 'Email' },
  { type: 'text', code: 'groups.code', label: 'Group Code' },
  { type: 'date', code: 'dob', label: 'DOB' },
  { type: 'date', code: 'created_at', label: 'Created At' },
  { type: 'date', code: 'updated_at', label: 'Updated At' }
]
