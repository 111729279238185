import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.canRead && !_vm.canCreate)?_c(VContainer,[_c('div',{staticClass:"d-flex justify-center"},[_c('restricted')],1)]):(_vm.canRead)?_c('resource-grid',{staticClass:"ma-4 justify-center",attrs:{"options":_vm.options,"items":_vm.items},on:{"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase",attrs:{"text":"","to":{ name: 'single-order', params: { id: item.number } }}},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c(item.customer.customer_id ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-update', params: { id: item.customer.customer_id } }}},[_vm._v(" "+_vm._s(item.customer.firstname)+" "+_vm._s(item.customer.lastname)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('order-status-chip',{attrs:{"value":item.status}}),(item.customer.guest_id)?_c('order-status-chip',{attrs:{"value":"guest"}}):_vm._e()]}},{key:"item.summary.total_due",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.money.withStoreCurrency(item.summary.total_due)))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}}]),model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.canCreate || !_vm.value.id,"small":"","depressed":"","loading":_vm.loadingCreate},on:{"click":_vm.createCart}},[_vm._v(" "+_vm._s(_vm.$t('Create order'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }